<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
      :title="__('Accounts')"
    />
    <el-button
      :disabled="!can('manage.accounts.write')"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Add Account") }}</el-button
    >
    <div style="padding: 0 36px;" v-loading="isLoading">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="accountTable"
            class="list-table"
            :data="accounts"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            v-show="can('manage.accounts.read')"
          >
            <el-table-column :label="__('ID')" prop="ac_id" />
            <el-table-column :label="__('Name')" prop="ac_name">
              <template slot-scope="scope">
                <el-row
                  type="flex"
                  justify="flex-start"
                  style="align-items: center"
                >
                  <img
                    :src="
                      scope.row.logo_url ||
                        scope.row.service_provider.data.logo_url ||
                        '/Five9Logo.jpg'
                    "
                    @error="handleAltImg"
                    class="rounded-menu-icon"
                    alt="AC Logo"
                  />
                  <span style="margin-left: 20px;">{{
                    accountNameLabel(scope.row)
                  }}</span>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              :label="__('Domain Id')"
              prop="ac_domain_map.domain_id"
            />
            <el-table-column :label="__('Note')" prop="note" />
            <el-table-column
              v-if="showingInactiveAccounts"
              :label="__('Days left')"
              prop="days_left"
            />
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";
import { EventBus } from "@/EventBus";
import * as AccountsUtil from "@/utils/accounts";

export default {
  name: "AccountsManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  props: {
    msg: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      sortByColumns: ["ac_name", "ac_id"],
      fetchedAccounts: false,
      search: "",
      filters: ["ac_name"],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      showingInactiveAccounts: false,
      navItems: [
        {
          label: "Active Accounts",
          displayText: __("Active Accounts"),
          active: true
        },
        {
          label: "Inactive Accounts",
          displayText: __("Inactive Accounts"),
          active: false
        }
      ]
    };
  },

  computed: {
    ...mapState("accounts", {
      accounts: state => state.accounts,
      isLoading: state => state.isLoading
    }),

    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),

    accountsToShow() {
      return this.accounts.filter(
        account =>
          !this.search || this.filterAccountOnSearchQuery(account, this.search)
      );
    }
  },

  mounted() {
    this.setAccountsFilter(1);
    if (this.msg) {
      this.$message({
        type: "success",
        message: this.msg
      });
    }
  },
  methods: {
    accountNameLabel: AccountsUtil.accountNameLabel,

    ...mapActions("accounts", {
      contentAPI: "getPaginatedAccountsForSP",
      getAccountsForServiceProvider: "getAccountsForServiceProvider",
      deleteContentMethod: "deleteAccount",
      undoDeleteContent: "undoDeleteAccount",
      setAccountsFilter: "setAccountsFilter"
    }),
    async initializeContents() {
      this.fetchedAccounts = false;
      if (!this.msg && this.selectedServiceProviderId !== "all") {
        await this.contentAPI();
      }
      this.fetchedAccounts = true;
    },

    handleAltImg(event) {
      event.target.src = "/Five9Logo.jpg";
      event.target.onerror = null;
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      if (row.is_enabled !== 0) {
        this.handleEdit(null, row);
      }
    },

    handleClear() {
      this.$refs.accountTable && this.$refs.accountTable.setCurrentRow();
      this.handleClearSelection();
    },

    handleDeleteItem(index, account) {
      this.handleDelete(account);
    },
    handleNavItemChanged(navItem) {
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      if (navItem.label === "Inactive Accounts") {
        this.showingInactiveAccounts = true;
      } else {
        this.showingInactiveAccounts = false;
      }
      _.find(this.navItems, { label: navItem.label }).active = true;
      this.setAccountsFilter(navItem.label === "Active Accounts" ? 1 : 0);
      EventBus.$emit("list-changed");
    }
  },
  watch: {
    selectedServiceProviderId: "initializeContents",
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.accountTable &&
            this.$refs.accountTable.setCurrentRow(this.accounts[val]);
        } else if (val === -1) {
          this.$refs.accountTable && this.$refs.accountTable.setCurrentRow();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/pagination-toolbar.scss";
.rounded-menu-icon {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  object-fit: cover;
  background: white;
}
</style>
