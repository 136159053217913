<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";
//import Vue from "vue";

export default {
  name: "AccountsManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("accounts", {
      contents: state => state.accounts
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      fetchTrunksForServiceProvider: "fetchTrunksForServiceProvider"
    }),

    ...mapActions("passwordpolicies", {
      getPasswordPolicies: "getPasswordPolicies"
    }),

    initContent() {
      return {
        ac_name: "",
        logopath: "",
        logo_url: "",
        total_outbound_threads: 0,
        ob_disabled: false,
        message_balance: 0,
        password_policy_id: "",
        default_timezone: "",
        use_file_svc: false,
        ac_domain_map: {
          data: {
            domain_id: "",
            domain_name: ""
          }
        }
      };
    }
  },

  mounted() {
    this.fetchTrunksForServiceProvider();
    this.getPasswordPolicies();
    this.contentType = "Account";
    this.primaryKey = "ac_id";
  }
};
</script>

<style lang="scss" scoped></style>
